import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Stack, Text, useToast } from "@chakra-ui/react";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/Charts/Popup";
import { Dropdown, Input, PaginatedTable } from "../../../components/common";
import Loader from "../../../components/common/Spinner";
import SwitchToggle from "../../../components/common/Switch";
import {
  DuplicateIcon,
  EmailVector,
  LedgerIcon,
  SearchIcon,
  StepsIcon,
} from "../../../components/icons/svg";
import { roleChecker } from "../../../utils";
import {
  deleteCampaign,
  deleteCategory,
  duplicateBDCcampaign,
  getBDCCampaign,
  updateCategories,
  updateStatus,
} from "../../../utils/api/bdc-campaign.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { Dealership_Roles } from "../../../utils/roles";
import { CreateBdcCampaign } from "./CreateCampaign/CreateBdcCampaign";
import { CreateCategory } from "./CreateCategory";
import { loadCategories } from "./CreateCampaign/utils";
export default function BDCCampaignPage({
  is_ro_tradeup = false,
  apiSet,
  dealership_id,
}: any) {
  const toast = useToast();
  const user = useUserInfo("user");
  const role = useUserInfo("role");
  const isSalesPerson = Dealership_Roles[2] === user?.role;

  const mutation = useMutation<any, Error, any>(
    apiSet ? apiSet?.updateStatus : updateStatus
  );
  const categoryMutation = useMutation<any, Error, any>(
    apiSet ? apiSet?.updateCategories : updateCategories
  );
  const duplicateMutation = useMutation<any, Error, any>(
    apiSet ? apiSet?.duplicateBDCcampaign : duplicateBDCcampaign
  );
  const deleteCampaignMutation = useMutation<any, Error, any>(
    apiSet ? apiSet?.deleteCampaign : deleteCampaign
  );
  const deleteCategoryMutation = useMutation<any, Error, any>(
    apiSet ? apiSet?.deleteCategory : deleteCategory
  );
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const id = useUserInfo("dealership")?.id ?? dealership_id;
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [categoryID, setCategoryID] = useState<any>();
  const [enrolledContacts, setEnrolledContacts] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    value: "",
  });
  const { data, isLoading, refetch } = useQuery<any, Error>({
    queryKey: ["bdc-campaign", id, page, perPage, query, is_ro_tradeup],
    queryFn: apiSet ? apiSet?.getBDCCampaign : getBDCCampaign,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const [isOpenCreateCategory, setIsOpenCreateCategory] = useState(false);
  const [isOpenCreateCampaign, setIsOpenCreateCampaign] = useState(false);

  const onOpenCreateCategory = () => setIsOpenCreateCategory(true);
  const onCloseCreateCategory = () => setIsOpenCreateCategory(false);
  const onOpenCreateCampaign = () => setIsOpenCreateCampaign(true);
  const onCloseCreateCampaign = () => setIsOpenCreateCampaign(false);
  const lead_intake_email = data?.data?.lead_intake_email ?? "";

  const handleSelect = (option: any, id: any, category_id?: any,category_name?:any) => {
    if (option == "10") {
      setPopup({
        name: "Duplicate Campaign",
        description: "Are you sure you want to duplicate this campaign?",
        value: id,
      });
      setCategoryID({value:category_id,label:category_name});
      onOpenPopupOpen();
    } else if (option == "11") {
      setPopup({
        name: "Delete Campaign",
        description: "Are you sure you want to delete this campaign?",
        value: id,
      });
      onOpenPopupOpen();
    } else {
      if (apiSet) {
        if (dealership_id) {
          navigate(`/global-bdc-campaign/${option}/${id}/${dealership_id}`, {
            replace: false,
          });
        } else {
          navigate(`/global-bdc-campaign/${option}/${id}`);
        }
      } else {
        const routeString = !is_ro_tradeup
          ? `/bdc-campaign/${option}/${id}`
          : `/bdc-campaign/${option}/${id}?is_ro_tradeup=${is_ro_tradeup}`;
        navigate(routeString);
      }
    }
  };
  const handleStatusCall = (camp_id: any) => {
    mutation?.mutate(
      {
        id: id,
        campaign_id: camp_id,
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          toast({
            description: "Status updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const updateCategoryCall = (category: any) => {
    categoryMutation?.mutate(
      {
        id: id,
        category_id: category?.category,
        payload: {
          name: category?.name,
        },
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          toast({
            description: "Category name updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const deleteCategoryCall = () => {
    deleteCategoryMutation?.mutate(
      {
        id: id,
        category_id: popup?.value,
      },
      {
        onSuccess(data, variables, context) {
          onClosePopupOpen();
          refetch();
          toast({
            description: "This category will be deleted shortly. Your request is being processed.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const deleteCampaignCall = () => {
    deleteCampaignMutation?.mutate(
      {
        id: id,
        bdc_id: popup?.value,
      },
      {
        onSuccess(data, variables, context) {
          onClosePopupOpen();
          refetch();
          toast({
            description: "Campaign deleted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const duplicateCall = () => {
    duplicateMutation?.mutate(
      {
        id: id,
        bdc_id: popup?.value,
        payload: {
          bdc_campaign: {
            enroll_contact: enrolledContacts,
            category_id: categoryID?.value,
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          onClosePopupOpen();
          toast({
            description: "Campaign duplicated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const options: SelectOptionProps[] = id
    ? [
        {
          label: (
            <Box as="span" display="flex" alignItems="center" gap="0.5rem">
              <SettingsIcon
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: "var(--grey-800)",
                }}
              />
              Settings
            </Box>
          ),
          value: "1",
        },
        {
          label: (
            <Box as="span" display="flex" alignItems="center" gap="0.5rem">
              <StepsIcon />
              Activities
            </Box>
          ),
          value: "2",
        },
        {
          label: (
            <Box as="span" display="flex" alignItems="center" gap="0.5rem">
              <PeopleAltIcon
                sx={{
                  width: "var(--icon-size)",
                  height: "var(--icon-size)",
                  color: "var(--grey-800)",
                }}
              />
              Leads
            </Box>
          ),
          value: "3",
        },
        {
          label: (
            <Box as="span" display="flex" alignItems="center" gap="0.5rem">
              <LedgerIcon />
              Ledger
            </Box>
          ),
          value: "4",
        },
        ...(isSalesPerson
          ? []
          : [
              {
                label: (
                  <Box
                    as="span"
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                  >
                    <DuplicateIcon />
                    Duplicate
                  </Box>
                ),
                value: "10",
              },
            ]),
      ]
    : [
        {
          label: (
            <Box as="span" display="flex" alignItems="center" gap="0.5rem">
              <SettingsIcon
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: "var(--grey-800)",
                }}
              />
              Settings
            </Box>
          ),
          value: "1",
        },
        {
          label: (
            <Box as="span" display="flex" alignItems="center" gap="0.5rem">
              <StepsIcon />
              Activities
            </Box>
          ),
          value: "2",
        },
        ...(isSalesPerson
          ? []
          : [
              {
                label: (
                  <Box
                    as="span"
                    display="flex"
                    alignItems="center"
                    gap="0.5rem"
                  >
                    <DuplicateIcon />
                    Duplicate
                  </Box>
                ),
                value: "10",
              },
            ]),
      ];

  const tableHeader = [
    { id: 2, value: "BDC Campaign", label: "campaign" },
    { id: 1, value: "Campaign ID", label: "campaign_id" },
    { id: 3, value: "Activities", label: "steps" },
    { id: 4, value: "Enrolled Contacts", label: "enrolledLeads" },
    { id: 5, value: "Total Actions", label: "totalTasks" },
    { id: 6, value: "Completed Actions", label: "completedTasks" },
    { id: 7, value: "Pending Actions", label: "pendingTasks" },
    { id: 9, value: "Actions", label: "actions" },
  ];
  const globaltableHeader = [
    { id: 2, value: "BDC Campaign", label: "campaign" },
    { id: 1, value: "Campaign ID", label: "campaign_id" },
    { id: 3, value: "Activities", label: "steps" },
    { id: 9, value: "Actions", label: "actions" },
  ];
  const tableData = data?.data?.bdc_campaigns?.map((item: any) => ({
    category: item?.category?.id,
    is_default:
      item?.category?.is_default ||
      (!roleChecker(role) && item?.category?.global_id)
        ? true
        : false,
    name: item?.category?.name,
    isEditing: false,
    data: item?.campaigns?.map((camp: any) => {
      const baseOptions = [...options];

      // Append delete option if `canDelete` is true
      if (!(camp?.default || (!roleChecker(role) && camp?.global_id))) {
        baseOptions.push({
          label: (
            <Box as="span" display="flex" alignItems="center" gap="0.5rem">
              <DeleteIcon />
              Delete
            </Box>
          ),
          value: "11",
        });
      }
      if (camp?.default && camp?.bdc_campaign_key === "appt_confirmation") {
        const index = baseOptions.findIndex((option) => option.value === "10");
        if (index !== -1) {
          baseOptions.splice(index, 1); // Remove the element at the found index
        }
      }
      if (camp?.default && !id) {
        const index = baseOptions.findIndex((option) => option.value === "10");
        if (index !== -1) {
          baseOptions.splice(index, 1); // Remove the element at the found index
        }
      }

      return {
        campaign_id: camp?.uuid,
        campaign: (
          <SwitchToggle
            id="1"
            onChange={(check) => handleStatusCall(camp?.id)}
            defaultChecked={camp?.active}
            label={
              <Stack direction="column">
                <Text textStyle="h6">{camp?.name}</Text>
              </Stack>
            }
          />
        ),
        steps: (
          <Box
            as="span"
            borderRadius="2.5rem"
            border="1px solid var(--secondary-600)"
            color="var(--secondary-600)"
            padding="0.25rem 0.62rem"
          >
            {camp?.activities}
          </Box>
        ),
        actions: (
          <Dropdown
            options={baseOptions}
            onSelect={(option) =>
              handleSelect(option?.value, camp?.id, item?.category?.id,item?.category?.name)
            }
          />
        ),
        enrolledLeads: (
          <Stack direction="row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.99856 7.92975C7.23546 7.92975 6.61155 7.68739 6.12682 7.20267C5.6421 6.71795 5.39974 6.09403 5.39974 5.33092C5.39974 4.56782 5.6421 3.94363 6.12682 3.45837C6.61155 2.9731 7.23546 2.73047 7.99856 2.73047C8.76166 2.73047 9.38651 2.9731 9.87311 3.45837C10.3597 3.94363 10.603 4.56782 10.603 5.33092C10.603 6.09403 10.3597 6.71795 9.87311 7.20267C9.38651 7.68739 8.76166 7.92975 7.99856 7.92975ZM3.70191 13.483C3.38414 13.483 3.11545 13.3725 2.89582 13.1515C2.67621 12.9305 2.56641 12.6625 2.56641 12.3475V11.8087C2.56641 11.3706 2.67678 10.993 2.89754 10.6759C3.11831 10.3589 3.40431 10.1184 3.75554 9.9544C4.5053 9.6184 5.22582 9.36641 5.91712 9.19842C6.60843 9.03042 7.30216 8.94642 7.99831 8.94642C8.69976 8.94642 9.39306 9.0332 10.0782 9.20675C10.7633 9.3803 11.4794 9.62951 12.2265 9.9544C12.5896 10.1153 12.882 10.3543 13.1037 10.6716C13.3255 10.9889 13.4363 11.3679 13.4363 11.8087V12.3475C13.4363 12.6625 13.3261 12.9305 13.1057 13.1515C12.8853 13.3725 12.6157 13.483 12.2968 13.483H3.70191Z"
                fill="#616161"
              />
            </svg>
            <Text>{camp?.enrolled_contacts} Leads</Text>
          </Stack>
        ),
        totalTasks: camp?.actions,
        pendingTasks: camp?.pending,
        completedTasks: (
          <Stack direction="row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M7.89375 10.7062L6.05625 8.86875C5.94375 8.75625 5.80625 8.7 5.64375 8.7C5.48125 8.7 5.3375 8.7625 5.2125 8.8875C5.1 9 5.04375 9.1375 5.04375 9.3C5.04375 9.4625 5.1 9.6 5.2125 9.7125L7.5 12.0187C7.6125 12.1312 7.74375 12.1875 7.89375 12.1875C8.04375 12.1875 8.175 12.1312 8.2875 12.0187L12.7687 7.5375C12.8937 7.4125 12.9562 7.26875 12.9562 7.10625C12.9562 6.94375 12.8937 6.8 12.7687 6.675C12.6437 6.5625 12.4969 6.50937 12.3281 6.51562C12.1594 6.52187 12.0187 6.58125 11.9062 6.69375L7.89375 10.7062ZM9 16.5C7.975 16.5 7.00625 16.3031 6.09375 15.9094C5.18125 15.5156 4.38437 14.9781 3.70312 14.2969C3.02187 13.6156 2.48437 12.8187 2.09062 11.9062C1.69687 10.9937 1.5 10.025 1.5 9C1.5 7.9625 1.69687 6.9875 2.09062 6.075C2.48437 5.1625 3.02187 4.36875 3.70312 3.69375C4.38437 3.01875 5.18125 2.48437 6.09375 2.09062C7.00625 1.69687 7.975 1.5 9 1.5C10.0375 1.5 11.0125 1.69687 11.925 2.09062C12.8375 2.48437 13.6312 3.01875 14.3062 3.69375C14.9812 4.36875 15.5156 5.1625 15.9094 6.075C16.3031 6.9875 16.5 7.9625 16.5 9C16.5 10.025 16.3031 10.9937 15.9094 11.9062C15.5156 12.8187 14.9812 13.6156 14.3062 14.2969C13.6312 14.9781 12.8375 15.5156 11.925 15.9094C11.0125 16.3031 10.0375 16.5 9 16.5ZM9 15.375C10.775 15.375 12.2812 14.7531 13.5187 13.5094C14.7562 12.2656 15.375 10.7625 15.375 9C15.375 7.225 14.7562 5.71875 13.5187 4.48125C12.2812 3.24375 10.775 2.625 9 2.625C7.2375 2.625 5.73438 3.24375 4.49062 4.48125C3.24687 5.71875 2.625 7.225 2.625 9C2.625 10.7625 3.24687 12.2656 4.49062 13.5094C5.73438 14.7531 7.2375 15.375 9 15.375Z"
                fill="#43A047"
              />
            </svg>
            <Text>{camp?.completed}</Text>
          </Stack>
        ),
        status: (
          <Box
            as="span"
            width="5.875rem"
            padding="0.25rem 0.625rem"
            borderRadius="2.5rem"
            background="var(--primary-50)"
            color="var(--primary-700)"
            fontSize="0.875rem"
          >
            Suspended
          </Box>
        ),
      };
    }),
  }));
  const handleDelete = (id: any) => {
    setPopup({
      name: "Delete Category",
      description:
        "Are you sure you want to delete this category and all its campaigns?",
      value: id?.category,
    });
    onOpenPopupOpen();
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(lead_intake_email).then(() => {
      toast({
        title: "Copied!",
        description: "Lead intake email has been copied to the clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };
  return (
    <>
      <Box
        background="white"
        padding="1.5rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flex="1" alignItems="center" gap="1rem">
          <Input
            maxW="320px"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Type here to search"
            onChange={(e) => {
              setQuery(e.target.value);
              setPage(1);
            }}
          />
          {lead_intake_email && (
            <Box
              display="flex"
              alignItems="center"
              gap="0.25rem"
              padding="0.5rem"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              _hover={{ backgroundColor: "var(--grey-100)" }}
              cursor="pointer"
              onClick={handleCopyToClipboard}
            >
              <EmailVector />
              <Text color="var(--grey-600)">Lead intake:</Text>
              <Text>{lead_intake_email}</Text>
            </Box>
          )}
        </Box>
        {!is_ro_tradeup && !isSalesPerson && (
          <Button onClick={onOpenCreateCampaign}>Create BDC Campaign</Button>
        )}
      </Box>
      <Box
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        margin="1.5rem"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="1rem"
        >
          <Text textStyle="caption" color="var(--grey-600)">
            All: {data?.data?.bdc_campaigns_count}
          </Text>
        </Box>
        <PaginatedTable
          header={id ? tableHeader : globaltableHeader}
          data={tableData}
          itemsPerPage={perPage}
          currentPage={page}
          maxPageNumbers={3}
          isPaginated={true}
          hasMultiBody={true}
          openDrawer={onOpenCreateCategory}
          isLoadingData={isLoading}
          handleSaveClick={updateCategoryCall}
          handleCancelClick={refetch}
          setCurrentPage={setPage}
          isSticky={true}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
            setPage(1);
          }}
          handleDelete={handleDelete}
          // rowOnClick={(rowItem) => {
          //   setClickedRow(rowItem);
          // }}

          rowCount={data?.data?.bdc_campaigns_count}
          is_ro_tradeup={is_ro_tradeup}
          isSalesPerson={isSalesPerson}
        />
      </Box>
      {isOpenCreateCategory && (
        <CreateCategory
          isOpen={isOpenCreateCategory}
          onClose={onCloseCreateCategory}
          refetch={() => {
            refetch();
          }}
          apiSet={apiSet}
          dealership_id={dealership_id}
        />
      )}
      {isOpenCreateCampaign && (
        <CreateBdcCampaign
          isOpen={isOpenCreateCampaign}
          onClose={onCloseCreateCampaign}
          refetch={refetch}
          is_ro_tradeup={is_ro_tradeup}
          apiSet={apiSet}
          dealership_id={dealership_id}
        />
      )}
      {(mutation?.isLoading ||
        categoryMutation?.isLoading ||
        deleteCategoryMutation?.isLoading ||
        deleteCampaignMutation?.isLoading) && <Loader />}
      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          onConfirm={
            popup?.name == "Duplicate Campaign"
              ? duplicateCall
              : popup?.name == "Delete Campaign"
              ? deleteCampaignCall
              : deleteCategoryCall
          }
          title={popup.name}
          message={popup.description}
          hasCheckbox={popup?.name == "Duplicate Campaign" ? true : false}
          checkboxContent="Include Enrolled Contacts"
          onCheckboxChange={(val) => setEnrolledContacts(val)}
          checkboxValue={enrolledContacts}
          loading={
            duplicateMutation?.isLoading ||
            deleteCampaignMutation?.isLoading ||
            deleteCategoryMutation?.isLoading
          }
          options= {(searchStr:any) =>
              loadCategories(searchStr, id,apiSet,is_ro_tradeup)
            }
            optionValue={categoryID}
          setOptionValue={(id: any) => setCategoryID(id)}
        />
      )}
    </>
  );
}
