import {
  Box,
  Button,
  DrawerFooter,
  Stack,
  Text,
  useToast,
  VStack,
  FormLabel,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { convertDateTime } from "../../utils";
import {
  createContactAppointment,
  createContactReminder,
  createDealershipAppointment,
  createMyAppointment,
  editAppointment,
  editContactReminder,
} from "../../utils/api/meeting.api";
import { fetchUsersDP } from "../../utils/api/users.api";
import {
  useTwilioDispatch,
  useTwilioState,
} from "../../utils/context/TwilioContext";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Drawer, Input, Select } from "../common";
import SelectRadio from "../common/SelectRadio";
import Loader from "../common/Spinner";
import { CalendarIcon, InfoIcon, TimeIcon } from "../icons/svg";
import {
  CreateAppointmentForm,
  createAppointmentValidation,
  createReminderValidation,
  defaultValues,
  LeadAppointmentOptions,
  ReminderOptions,
} from "./util";
import AppointmentReasonModal from "./AppointmentReasonModal";
import CustomTimeInput from "../common/CustomInput";
import AsyncSelect from "react-select/async";
import { loadUsers } from "../Drawers/SearchAndFilterDrawer/utils";
interface Type {
  value?: any;
  // Other properties if any
}
export const CreateAppointment = ({
  isOpen,
  onClose,
  slotData,
  apiCall,
  myCalendar,
  editMode,
  contactid,
  leadReminder,
  isHistoryAppointment,
}: any) => {
  const toast = useToast();
  const dealership = useUserInfo("dealership");
  const userId = useUserInfo("id");
  const { openAppointment } = useTwilioState();
  const dispatch = useTwilioDispatch();
  const [reason, setReason] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const mutation = useMutation(
    contactid && !leadReminder
      ? createContactAppointment
      : leadReminder
      ? createContactReminder
      : myCalendar
      ? createMyAppointment
      : createDealershipAppointment
  );
  const editmutation = useMutation(
    leadReminder ? editContactReminder : editAppointment
  );
  const { handleSubmit, control, setValue, reset, watch } =
    useForm<CreateAppointmentForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(
        myCalendar ? createReminderValidation : createAppointmentValidation
      ) as any,
    });
  const handleFormSubmit = (values: CreateAppointmentForm) => {
    let newdate = moment(values.date).format("yyyy-MM-DD");
    mutation.mutate(
      {
        id: dealership.id,
        userId: userId,
        contactid: contactid,
        data: {
          event: {
            title:
              leadReminder || myCalendar || !contactid
                ? values.title
                : undefined,
            start_time: convertDateTime(newdate, values.start_time),
            // start_time: convertDateTime(newdate, moment(values?.start_time)?.format("HH:mm")),
            event_type: (values?.type as Type)?.value,
            participant_ids: myCalendar
              ? undefined
              : values?.userid?.map((item) => item.value),
            // status: "set_appointment",
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          handleClose();
          apiCall();
          toast({
            description: "Event created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const handleEditFormSubmit = (values: CreateAppointmentForm) => {
    let newdate = moment(values.date).format("yyyy-MM-DD");
    editmutation.mutate(
      {
        id: dealership.id,
        meetingId: slotData?.id,
        contactid: contactid,
        data: {
          event: {
            title: values.title,
            start_time: convertDateTime(newdate, values.start_time),
            event_type: (values?.type as Type)?.value,
            participant_ids: myCalendar
              ? undefined
              : values?.userid?.map((item) => item.value),
            // status: "set_appointment",
            reason: reason,
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          handleClose();
          apiCall();
          toast({
            description: "Event edited successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const handleModalSubmit = (values: any) => {
    setModalOpen(false);
    handleSubmit((values) => handleEditFormSubmit(values))();
  };

  // Set default values for start time, end time, and date based on slotData
  useEffect(() => {
    if (slotData) {
      reset(slotData);
    }
  }, [slotData]);

  const handleClose = () => {
    dispatch({ type: "OPEN_APPOINTMENT", payload: { openAppointment: false } });
    onClose();
  };

  return (
    <>
      <Drawer
        isOpen={isOpen || openAppointment}
        onClose={handleClose}
        title={
          leadReminder || myCalendar || !contactid
            ? "Create Reminder"
            : !editMode
            ? "Create an Appointment"
            : "Reschedule the Appointment"
        }
      >
        <VStack padding="1rem 1.3rem" alignItems="flex-start" w="100%">
          {(leadReminder || myCalendar || !contactid) && (
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label={myCalendar ? "Reminder Name" : "Title"}
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    isRequired
                    type="text"
                    maxW="100%"
                  />
                );
              }}
              name="title"
              control={control}
              rules={{
                required: true,
              }}
            />
          )}
          {!myCalendar && (
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  // <Select
                  //   label="Select User"
                  //   isMulti={true}
                  //   onChange={onChange}
                  //   value={value}
                  //   placeholder=""
                  //   options={
                  //     data?.users?.map((user: any) => ({
                  //       label: user?.full_name,
                  //       value: user?.id,
                  //     })) ?? []
                  //   }
                  //   variant="default"
                  //   w="100%"
                  //   error={error?.message}
                  // />
                  <Box mb="1rem">
                    <FormLabel
                      mb="0.31rem"
                      color="var(--grey-900)"
                      fontSize="0.875rem"
                      fontWeight="600"
                      lineHeight="1rem"
                    >
                      Select Users
                    </FormLabel>
                    <AsyncSelect
                      defaultOptions={true}
                      loadOptions={(inputValue) =>
                        loadUsers(
                          inputValue,
                          dealership?.id,
                          "Owner/GM,Manager/Admin,Sales Person,BDC Agent"
                        )
                      }
                      isMulti
                      value={value}
                      onChange={(value: any) => {
                        onChange(value);
                      }}
                      placeholder="Select Users"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "470px",
                        }),
                      }}
                    />{" "}
                  </Box>
                );
              }}
              name="userid"
              control={control}
              rules={{
                required: true,
              }}
            />
          )}

          <Box minW={"100%"} className="calenderDatePicker">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  selected={value ? new Date(value) : null}
                  onChange={(date) => {
                    onChange(date);
                  }}
                  dateFormat="MM/dd/yyyy"
                  customInput={
                    <Input
                      label="Select Date"
                      hasIcon={true}
                      isIconRight={true}
                      icon={<CalendarIcon />}
                      maxW="100%"
                      minW={"100%"}
                      error={error?.message}
                      placeholder={"mm/dd/yyyy"}
                    />
                  }
                />
              )}
              name="date"
              control={control}
              rules={{ required: true }}
            />
          </Box>

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                // <Input
                //   onChange={onChange}
                //   value={value}
                //   label="Select Time"
                //   hasIcon={true}
                //   isIconRight={true}
                //   icon={<TimeIcon />}
                //   type="time"
                //   maxW="100%"
                //   error={error?.message}
                //   isRequired
                // />

                <CustomTimeInput
                  type="time"
                  label="Select Time"
                  value={value} // Ensure string or null
                  onChange={onChange}
                  isRequired={true}
                  error={error?.message}
                  hasIcon={true}
                  isIconRight={true} // Set to false if the icon is on the left
                  icon={<TimeIcon />}
                />
              );
            }}
            name="start_time"
            control={control}
            rules={{
              required: true,
            }}
          />

          {contactid && !leadReminder && (
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <SelectRadio
                    options={
                      contactid && !leadReminder
                        ? LeadAppointmentOptions
                        : ReminderOptions
                    }
                    hasIcon={true}
                    isStepper={false}
                    labelDark={true}
                    label="Select Appointment Type"
                    onChange={onChange}
                    defaultValue={value}
                  />
                );
              }}
              name="type"
              control={control}
              rules={{
                required: true,
              }}
            />
          )}
          {myCalendar && (
            <Stack direction="row">
              <InfoIcon color="var(--primary-600)" />
              <Text textStyle="h6">
                Please note that Meeting Appointments and Service Appointments
                must be scheduled within an individual contact's profile.
              </Text>
            </Stack>
          )}

          <DrawerFooter
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            borderTop="1px solid var(--grey-300)"
            display="flex"
            alignItems="center"
            gap="0.81rem"
          >
            <Button variant="outline" w="100%" size="xl" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              w="100%"
              size="xl"
              onClick={(event) => {
                event.preventDefault();
                editMode
                  ? handleSubmit((values) =>
                      isHistoryAppointment
                        ? setModalOpen(true)
                        : handleEditFormSubmit(values)
                    )()
                  : handleSubmit((values) => handleFormSubmit(values))();
              }}
            >
              {editMode ? "Edit" : "Create"}
            </Button>
          </DrawerFooter>
        </VStack>
        {(mutation?.isLoading || editmutation?.isLoading) && <Loader />}
      </Drawer>
      {isModalOpen && (
        <AppointmentReasonModal
          isOpen={isModalOpen}
          headerText="Reason for Rescheduling"
          bodyText="Please provide a reason for rescheduling this appointment:"
          setModalOpen={setModalOpen}
          onSubmit={handleModalSubmit}
          reason={reason}
          setReason={setReason}
        />
      )}
    </>
  );
};
