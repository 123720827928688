import { Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../components/common";
import { fetchHome } from "../../../utils/api/guest-mode";
import MessageThread from "../../../components/LeadCenter/LeadDetails/DetailTabs/History/Messages/MessageThread";
import { useMessageThread } from "../../../components/LeadCenter/LeadDetails/DetailTabs/History/Messages/useMessageThread";

export default function GuestMessageThread() {
  const { id, dId } = useParams();

//   const messageThreadProps = useMessageThread({
//     clickedRecord,
//     clickedRow,
//     type: "message",
//     activeIndex: 2,
//   });

  return (
    <VStack w="100%" mt="2.5rem" px={{ base: "1rem", md: "0" }} height="calc(100vh - 64px)" overflow="auto">
        {/* <MessageThread id={id} dId={dId} /> */}
        <Text variant="h1">Message Tread</Text>
    </VStack>
  );
}
